import React from 'react'
import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import MetaHeader from '../components/MetaHeader'

const NotFoundPage = () => (
  <Layout>
    <MetaHeader title="404 - Page Not Found" hidden />
    <h1>NOT FOUND</h1>
    <PageWrapper>
      <section>
        <p>You just hit a route that doesn’t exist... :(</p>
      </section>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage
